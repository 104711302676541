<template #page-content>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Office</h2>
    </template>
    <template #action-bar>
      <div class="level">
        <div class="level-left">
          <b-button
            tag="button"
            class="is-command"
            icon-left="plus"
            @click.native="openModalCreate()"
          >
            New Office
          </b-button>
        </div>
      </div>
    </template>
    <template #page-content>
      <div class="columns is-multiline">
        <div class="column is-4" v-for="(item, index) in data" :key="index">
          <r-card
            @click.native="openSidebar(item)"
            class="pointer-click office-card"
          >
            <div class="columns is-multiline">
              <div class="is-12 office-picture-container">
                <img :src="getImageURL(item)" />
                <b-icon
                  icon="pencil-circle"
                  class="office-button-container office-pointer-click"
                  @click.native="openModalCreate(index)"
                ></b-icon>
              </div>
              <div class="column is-12 office-text-bold">
                {{ item ? item.locationName : '-' }}
              </div>
              <div class="column is-12 office-text-semi-bold">
                {{ item ? item.country + ' - ' + item.city : '-' }}
              </div>
              <div class="column is-12 office-text-regular">
                {{
                  item
                    ? textSubString(item.address, 80, '...') +
                      ' - ' +
                      item.postalCode
                    : '-'
                }}
              </div>
              <div class="column is-6 pl-0 pb-1 pt-1">
                <div class="column is-12 person-text-regular">Max Capacity</div>
                <div class="column is-12 person-capacity">
                  {{ item ? item.maxCapacity : '-' }}
                  <span>people</span>
                </div>
              </div>
              <div class="column is-6 pl-0 pb-1 pt-1">
                <div class="column is-12 person-text-regular">
                  Allowed Capacity
                </div>
                <div class="column is-12 person-capacity">
                  {{
                    item.activeSetting ? item.activeSetting.maxCapacity : '-'
                  }}
                  <span>people</span>
                </div>
              </div>
            </div>
          </r-card>
        </div>
      </div>
      <r-page-modal
        :prompt-on-leave="false"
        prompt-message="Are You sure?"
        v-if="isModalOpen"
        @hide="closeModalCreate"
        key="modalKey"
        :is-modal="true"
        class="modal-office-type"
      >
        <template #page-content>
          <h3 class="modal-title has-text-weight-black">
            {{ isEditing ? 'Edit Company' : 'Create Company' }}
            <span class="office-pointer-click is-pulled-right">
              <b-icon
                icon="close"
                size="is-small"
                @click.native="closeModalCreate"
              ></b-icon>
            </span>
          </h3>
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form
              @submit.prevent="handleSubmit(submit)"
              class="is-label-14-px-normal form-timeoff-type"
              multipart
            >
              <div class="columns is-multiline">
                <div class="column is-12">
                  <ValidationProvider
                    name="company name"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-field
                      label="Company Name"
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <b-input
                        v-model="currentRow.locationName"
                        placeholder="Your Company Name"
                      ></b-input>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-12">
                  <ValidationProvider
                    name="address"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-field
                      label="Address"
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <b-input
                        v-model="currentRow.address"
                        placeholder="Your Company Address"
                      ></b-input>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column is-12">
                  <ValidationProvider
                    name="country"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-field
                      label="Country"
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <b-input
                        v-model="currentRow.country"
                        placeholder="Your Company Country"
                      ></b-input>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-12">
                  <ValidationProvider
                    name="province"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-field
                      label="Province"
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <b-input
                        v-model="currentRow.province"
                        placeholder="Province"
                      ></b-input>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-12">
                  <ValidationProvider
                    name="city"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <b-field
                      label="City"
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <b-input
                        v-model="currentRow.city"
                        placeholder="City"
                      ></b-input>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-12">
                  <ValidationProvider
                    name="postal code"
                    v-slot="{ errors }"
                    rules="required|numeric"
                  >
                    <b-field
                      label="Post Code"
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <b-input
                        v-model="currentRow.postalCode"
                        placeholder="e.g 40226"
                      ></b-input>
                    </b-field>
                  </ValidationProvider>
                </div>

                <div class="column is-12">
                  <ValidationProvider
                    name="lat_lng"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <b-field label="Location Details">
                          <GoogleMap
                            class="location-wrapper"
                            enable-place-marker
                            :limit-pick-marker="1"
                            marker-deletable
                            v-model="currentRow.markers"
                            draggable-marker
                          ></GoogleMap>
                        </b-field>
                      </div>
                      <div class="column is-12">
                        <p class="has-text-danger">{{ errors[0] }}</p>
                      </div>
                    </div>
                  </ValidationProvider>
                </div>

                <div class="column is-12">
                  <b-field label="Latitude">
                    <b-input
                      v-model="lat"
                      disabled
                      placeholder="Latitude"
                    ></b-input>
                  </b-field>
                </div>

                <div class="column is-12">
                  <b-field label="Longitude">
                    <b-input
                      v-model="lng"
                      disabled
                      placeholder="Latitude"
                    ></b-input>
                  </b-field>
                </div>

                <div class="column is-12">
                  <ValidationProvider
                    name="max capacity"
                    v-slot="{ errors }"
                    rules="required|numeric"
                  >
                    <b-field
                      label="Max Capacity Office"
                      :type="errors[0] && 'is-danger'"
                      :message="errors[0]"
                    >
                      <b-input
                        v-model="currentRow.maxCapacity"
                        placeholder="Max Capacity"
                      ></b-input>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div
                  class="column is-12"
                  v-if="currentRow.locationImage == null"
                >
                  <b-field label="Upload Office's Photo">
                    <b-upload
                      v-model="currentRow.locationImage"
                      accept="image/*"
                      drag-drop
                      expanded
                      @input="onFileChange"
                    >
                      <section class="section">
                        <div class="content has-text-centered">
                          <p>
                            <b-icon icon="image" size="is-large"></b-icon>
                          </p>
                          <p>
                            Drag image here or
                            <span class="has-red-text">Browse</span>
                          </p>
                        </div>
                      </section>
                    </b-upload>
                  </b-field>
                </div>

                <div
                  class="column is-12"
                  v-if="currentRow.locationImage != null"
                >
                  <b-field label="File to Upload">
                    <div
                      class="is-12 office-picture-container"
                      v-if="currentRow.locationImage != null"
                    >
                      <img v-if="url" :src="url" />
                      <img :src="getImageURL(currentRow)" v-else />
                      <b-icon
                        class="delete is-small office-button-container office-pointer-click"
                        @click.native="deleteDropFile()"
                      ></b-icon>
                    </div>
                  </b-field>
                </div>

                <div class="column is-12">
                  <b-button
                    expanded
                    native-type="submit"
                    type="is-primary"
                    :loading="isSubmitting"
                  >
                    Next
                  </b-button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </template>
      </r-page-modal>
      <r-page-modal
        :prompt-on-leave="false"
        prompt-message="Are You sure?"
        v-if="isModalWFOOpen"
        @hide="closeModalWFOCreate"
        :is-modal="true"
        key="modalKeyWFO"
        class="modal-allowed-capacity"
        remove-scrolling
      >
        <template #page-content>
          <h3 class="modal-title has-text-weight-black">
            {{ isEditingAllowedCap ? 'Edit Company' : 'Create Company' }}
            <span class="office-pointer-click is-pulled-right">
              <b-icon
                icon="close"
                size="is-small"
                @click.native="closeModalWFOCreate"
              ></b-icon>
            </span>
          </h3>
          <ValidationObserver ref="observer2" v-slot="{ handleSubmit }">
            <form
              @submit.prevent="handleSubmit(submitAllowedCap)"
              class="is-label-14-px-normal office-text-bold-allowed-cap"
            >
              <div class="columns is-multiline">
                <div class="column is-12">
                  <b-field>
                    <b-switch
                      v-model="allowedCapRow.isActive"
                      :left-label="true"
                      :value="true"
                      type="is-primary"
                    >
                      Allowed Capacity
                    </b-switch>
                  </b-field>
                </div>
                <div class="column is-12">
                  <b-field label="Allowed People">
                    <b-input
                      v-model="allowedCapRow.maxCapacity"
                      placeholder="e.g 20 people"
                      :disabled="!allowedCapRow.isActive"
                    ></b-input>
                  </b-field>
                </div>
                <div class="column is-6">
                  <b-field label="Start Date">
                    <b-datepicker
                      :rules="allowedCapRow.isActive ? 'required' : ''"
                      v-model="allowedCapRow.startAt"
                      placeholder="Start Date"
                      :disabled="!allowedCapRow.isActive"
                      trap-focus
                      editable
                    ></b-datepicker>
                  </b-field>
                </div>
                <div class="column is-6">
                  <b-field label="End Date">
                    <b-datepicker
                      :rules="allowedCapRow.isActive ? 'required' : ''"
                      v-model="allowedCapRow.endAt"
                      placeholder="End Date"
                      :disabled="!allowedCapRow.isActive"
                      trap-focus
                      editable
                    ></b-datepicker>
                  </b-field>
                </div>
                <div class="column is-12">
                  <b-button
                    expanded
                    native-type="submit"
                    type="is-primary"
                    :loading="isSubmittingWFO"
                  >
                    Save Changes
                  </b-button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </template>
      </r-page-modal>
    </template>
    <template #page-sidebar>
      <b-sidebar
        v-if="showRow"
        position="static"
        right
        v-model="isSideBarOpen"
        fullheight
      >
        <r-card outlined>
          <div class="level">
            <div class="level-left">
              <h3 class="mb-0">{{ showRow.locationName }}</h3>
            </div>
            <div class="level-right">
              <b-icon
                icon="close"
                class="office-pointer-click"
                size="is-small"
                @click.native="closeSidebar()"
              ></b-icon>
            </div>
          </div>
          <div class="office-picture-container">
            <img :src="getImageURL(showRow)" />
          </div>
          <div class="office-text-bold-sidebar pb-4">
            Company Details
            <span>
              <b-icon
                class="is-pulled-right pointer-click pt-2"
                size="is-small"
                icon="delete"
                @click.native="deleteAlert(showRow.id)"
              ></b-icon>
            </span>
          </div>
          <div class="columns is-multiline">
            <div class="column is-4 office-text-regular-sidebar">Address</div>
            <div class="column is-8 office-text-semibold-sidebar is-inline">
              {{ showRow.address ? showRow.address : '-' }}
            </div>

            <div class="column is-12"></div>

            <div class="column is-4 office-text-regular-sidebar">Province</div>
            <div class="column is-8 office-text-semibold-sidebar is-inline">
              {{ showRow.province ? showRow.province : '-' }}
            </div>
            <div class="column is-4 office-text-regular-sidebar">City</div>
            <div class="column is-8 office-text-semibold-sidebar">
              {{ showRow.city ? showRow.city : '-' }}
            </div>
            <div class="column is-4 office-text-regular-sidebar">Country</div>
            <div class="column is-8 office-text-semibold-sidebar">
              {{ showRow.country ? showRow.country : '-' }}
            </div>
            <div class="column is-4 office-text-regular-sidebar">
              Postal Code
            </div>
            <div class="column is-8 office-text-semibold-sidebar">
              {{ showRow.postalCode ? showRow.postalCode : '-' }}
            </div>
            <div
              class="column is-12"
              v-if="showRow && showRow.markers.length > 0"
            >
              <GoogleMap
                class="location-wrapper"
                :value="showRow.markers"
              ></GoogleMap>
            </div>
            <div class="column is-4 office-text-regular-sidebar">Latitude</div>
            <div class="column is-8 office-text-semibold-sidebar">
              {{ showRow.markers[0].lat }}
            </div>

            <div class="column is-4 office-text-regular-sidebar">Longitude</div>
            <div class="column is-8 office-text-semibold-sidebar">
              {{ showRow.markers[0].lng }}
            </div>

            <div class="column is-4 office-text-regular-sidebar">
              Max Capacity
            </div>
            <div class="column is-8 office-text-semibold-sidebar">
              {{ showRow.maxCapacity ? showRow.maxCapacity + ' People' : '-' }}
            </div>
            <div class="column is-12 mt-0">
              <hr class="black-divider" />
            </div>
          </div>
          <div class="office-text-bold-sidebar pt-1 pb-4">Allowed Capacity</div>
          <div class="columns is-multiline">
            <div class="column is-4 office-text-regular-sidebar">
              Allowed Capacity
            </div>
            <div class="column is-8 office-text-semibold-sidebar">
              {{
                showRow.activeSetting
                  ? showRow.activeSetting.maxCapacity + ' People'
                  : '-'
              }}
            </div>
            <div class="column is-4 office-text-regular-sidebar">Date Plan</div>
            <div class="column is-8 office-text-semibold-sidebar">
              {{
                showRow.activeSetting
                  ? formatDate(
                      new Date(showRow.activeSetting.startAt),
                      'DD MMMM'
                    ) +
                    ' - ' +
                    formatDate(
                      new Date(showRow.activeSetting.endAt),
                      'DD MMMM YYYY'
                    )
                  : '-'
              }}
            </div>
          </div>
        </r-card>
      </b-sidebar>
    </template>
  </r-page>
</template>
<script>
import ApiService from '@/services/common/api.service'
import { showToast } from '@/services/util'
import GoogleMap from '../../../components/GoogleMap'
import moment from 'moment'

export default {
  name: 'OfficeIndex',
  components: {
    GoogleMap,
  },
  computed: {
    lat: function () {
      if (this.currentRow.markers.length === 0) {
        return null
      }
      return this.currentRow.markers[0].lat
    },
    lng: function () {
      if (this.currentRow.markers.length === 0) {
        return null
      }
      return this.currentRow.markers[0].lng
    },
  },
  data() {
    return {
      data: [],
      form: [],
      showRow: null,
      currentRow: {
        id: null,
        address: null,
        locationName: null,
        country: null,
        city: null,
        province: null,
        postalCode: null,
        maxCapacity: null,
        locationImage: null,
        markers: [],
      },
      allowedCapRow: {},
      isEditing: false,
      isSubmitting: false,
      isSubmittingWFO: false,
      isModalOpen: false,
      isModalWFOOpen: false,
      fileImagetoShow: null,
      isSideBarOpen: false,
      isEditingAllowedCap: false,
      defaultLocImage: {},
      selectedLocation: {},
      url: null,
      img: null,
    }
  },
  async mounted() {
    this.getApiLocations()
    this.getApiWFOSettings()
  },
  methods: {
    onFileChange(e) {
      const file = e
      this.url = URL.createObjectURL(file)
      URL.revokeObjectURL(file)
    },

    formatDate(date, format) {
      return moment(date).format(format)
    },

    openSidebar(row) {
      console.log('row is')
      console.log(row)
      this.isSideBarOpen = true
      this.showRow = {
        id: row.id,
        address: row.address,
        locationName: row.locationName,
        country: row.country,
        city: row.city,
        province: row.province,
        postalCode: row.postalCode,
        maxCapacity: row.maxCapacity,
        activeSetting: row.activeSetting,
        locationImage: row.locationImage,
        markers: [
          {
            lat: +row.lat,
            lng: +row.long,
          },
        ],
      }
      this.fileImagetoShow = row.locationImage
    },

    closeSidebar() {
      this.isSideBarOpen = false
      this.showRow = null
    },

    getApiLocations() {
      ApiService.get('/api/space-roketin/location').then((response) => {
        this.data = response.data
      })
    },

    deleteAlert(id) {
      this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'button is-primary',
          cancelButton: 'button',
        },
        titleText: 'Are you sure you want to delete this office?',
      }).then(async (res) => {
        if (res && res.isConfirmed) {
          await ApiService.delete('/api/space-roketin/location/' + id).then(
            () => {
              this.$swal({
                icon: 'success',
                titleText: 'Thank You',
                confirmButtonText: 'Done',
                customClass: {
                  confirmButton: 'button is-success',
                },
              })
              this.closeSidebar()
              this.getApiLocations()
            }
          )
        }
      })
    },

    getApiWFOSettings() {
      ApiService.get('/api/space-roketin/settings/wfo-settings').then(
        (response) => {
          this.form = response.data
        }
      )
    },

    getImageURL(item) {
      return item.locationImage
        ? process.env.VUE_APP_API_URL + item.locationImage
        : '/images/default-profile-picture-male.png'
    },

    async openModalCreate(index) {
      this.selectedLocation = this.data[index]
      console.log('selected location is')
      console.log(this.selectedLocation)
      if (this.selectedLocation) {
        this.isEditing = true
        this.currentRow = {
          id: this.selectedLocation.id,
          address: this.selectedLocation.address,
          locationName: this.selectedLocation.locationName,
          country: this.selectedLocation.country,
          city: this.selectedLocation.city,
          province: this.selectedLocation.province,
          postalCode: this.selectedLocation.postalCode,
          maxCapacity: this.selectedLocation.maxCapacity,
          locationImage: this.selectedLocation.locationImage,
          markers: [
            {
              lat: +this.selectedLocation.lat,
              lng: +this.selectedLocation.long,
            },
          ],
        }
        this.fileImagetoShow = this.selectedLocation.locationImage
        this.defaultLocImage = this.selectedLocation.locationImage
      }
      this.isModalOpen = true
    },

    openModalAllowedCapCreate(row, id) {
      console.log('open modal allowed cap create triggered')
      if (this.selectedLocation) {
        let activeSetting = this.selectedLocation.activeSetting
        console.log('active setting is')
        console.log(activeSetting)
        if (activeSetting) {
          this.isEditingAllowedCap = true
          this.allowedCapRow = {
            id: activeSetting.id,
            locationId: id,
            maxCapacity: activeSetting.maxCapacity,
            startAt: new Date(activeSetting.startAt),
            endAt: new Date(activeSetting.endAt),
            isActive: activeSetting.isActive == 1,
          }
        } else {
          this.allowedCapRow = {
            id: null,
            locationId: id,
            maxCapacity: 0,
            startAt: null,
            endAt: null,
            isActive: false,
          }
        }
      } else {
        this.allowedCapRow = {
          id: null,
          locationId: id,
          maxCapacity: 0,
          startAt: null,
          endAt: null,
          isActive: false,
        }
      }
    },

    closeModalCreate() {
      this.resetForm()
      this.isModalOpen = false
      this.isEditing = false
    },

    closeModalWFOCreate() {
      this.resetWFOForm()
      this.isModalWFOOpen = false
      this.isEditing = false
    },

    deleteDropFile() {
      this.currentRow.locationImage = null
      this.url = null
    },

    resetForm() {
      this.currentRow = {
        id: '',
        address: '',
        locationName: '',
        country: '',
        city: '',
        province: '',
        postalCode: '',
        maxCapacity: null,
        locationImage: null,
        markers: [],
      }
      this.url = null
    },

    resetWFOForm() {
      this.allowedCapRow = {
        id: '',
        locationId: '',
        maxCapacity: null,
        startAt: '',
        endAt: '',
        isActive: 0,
      }
      this.isEditingAllowedCap = false
    },

    async submit() {
      ApiService.setHeaderMultipartFormData()
      let form = new FormData()
      this.isSubmitting = true

      try {
        if (!this.isEditing) {
          form.append('location_name', this.currentRow.locationName)
          form.append('address', this.currentRow.address)
          form.append('country', this.currentRow.country)
          form.append('city', this.currentRow.city)
          form.append('province', this.currentRow.province)
          form.append('postal_code', this.currentRow.postalCode)
          form.append('max_capacity', this.currentRow.maxCapacity)
          form.append('location_image', this.currentRow.locationImage)
          console.log('markers is')
          console.log(this.currentRow.markers)
          form.append('lat', this.currentRow.markers[0].lat)
          form.append('long', this.currentRow.markers[0].lng)

          let response = await ApiService.post(
            '/api/space-roketin/location',
            form,
            true
          )
          this.currentRow = {
            // id: '',
            address: '',
            locationName: '',
            country: '',
            city: '',
            province: '',
            postalCode: '',
            maxCapacity: null,
            locationImage: null,
          }

          this.data = []
          this.getApiLocations()
          showToast('Add Success', 'is-success', 'is-bottom')
          this.isModalOpen = false
          this.isModalWFOOpen = true

          this.openModalAllowedCapCreate(form, response.data.data.id)
        } else {
          form.append('location_name', this.currentRow.locationName)
          form.append('address', this.currentRow.address)
          form.append('country', this.currentRow.country)
          form.append('city', this.currentRow.city)
          form.append('province', this.currentRow.province)
          form.append('postal_code', this.currentRow.postalCode)
          form.append('max_capacity', this.currentRow.maxCapacity)
          form.append('lat', this.currentRow.markers[0].lat)
          form.append('long', this.currentRow.markers[0].lng)

          if (this.currentRow.locationImage !== this.defaultLocImage) {
            form.append('location_image', this.currentRow.locationImage)
          }

          form.append('_method', 'PUT')

          await ApiService.post(
            '/api/space-roketin/location/' + this.currentRow.id,
            form,
            true
          )

          this.data = []
          this.getApiLocations()
          this.isSubmitting = true
          this.closeSidebar()
          showToast('Data is successfully edited!', 'is-success', 'is-bottom')
          this.isModalOpen = false
          this.isModalWFOOpen = true
          this.openModalAllowedCapCreate(form, this.currentRow.id)
        }
      } catch (e) {
        showToast(e.response.data.message, 'is-danger', 'is-bottom')
      }

      this.isSubmitting = false
      this.isEditing = false

      this.resetForm()
      this.isModalOpen = false
    },

    async submitAllowedCap() {
      this.isSubmittingWFO = true

      try {
        if (!this.isEditingAllowedCap) {
          let allowedCapForm = {
            location_id: this.allowedCapRow.locationId,
            max_capacity: this.allowedCapRow.maxCapacity,
            start_at: this.allowedCapRow.startAt
              ? moment(this.allowedCapRow.startAt).format('YYYY-MM-DD')
              : null,
            end_at: this.allowedCapRow.endAt
              ? moment(this.allowedCapRow.endAt).format('YYYY-MM-DD')
              : null,
            is_active: this.allowedCapRow.isActive,
          }

          await ApiService.post(
            '/api/space-roketin/settings/wfo-settings',
            allowedCapForm,
            true
          )
          this.allowedCapRow = {
            id: '',
            locationId: '',
            maxCapacity: null,
            startAt: '',
            endAt: '',
            isActive: 0,
          }

          this.getApiLocations()
          // this.getApiWFOSettings()
          showToast('Add Success', 'is-success', 'is-bottom')
        } else {
          let allowedCapForm = {
            location_id: this.allowedCapRow.locationId,
            max_capacity: this.allowedCapRow.maxCapacity,
            start_at: moment(this.allowedCapRow.startAt).format('YYYY-MM-DD'),
            end_at: moment(this.allowedCapRow.endAt).format('YYYY-MM-DD'),
            is_active: this.allowedCapRow.isActive,
          }

          await ApiService.put(
            '/api/space-roketin/settings/wfo-settings/' + this.allowedCapRow.id,
            allowedCapForm,
            true
          )

          this.getApiLocations()
          // this.getApiWFOSettings()
          showToast('Add Success', 'is-success', 'is-bottom')
        }
      } catch (e) {
        showToast(e.response.data.message, 'is-danger', 'is-bottom')
      }

      this.isSubmittingWFO = false
      this.isEditing = false

      this.resetWFOForm()
      this.isModalWFOOpen = false
    },
  },
}
</script>
